import React, { useState } from 'react';
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import './faq.css';
import { Helmet } from 'react-helmet';

const questions = [
  {
    id: 1,
    question: 'What courses does Enack offer?',
    answer: 'Enack specializes in career-focused courses in Machine Learning, Web Development, and Business Analytics. These courses are designed to give you the skills needed for in-demand careers in tech and business.',
  },
  {
    id: 2,
    question: 'Do you offer courses that prepare me for a career in tech?',
    answer: 'Yes, our Machine Learning and Web Development courses are specifically designed to equip you with the skills necessary for roles like Data Scientist, Machine Learning Engineer, Web Developer, and more. Each course includes hands-on projects to prepare you for real-world job opportunities.',
  },
  {
    id: 3,
    question: 'How long do the Machine Learning and Web Development courses take?',
    answer: 'Each course typically takes about 12 months to complete, depending on your pace. You can expect a mix of live sessions, assignments, and projects designed to help you build a strong portfolio and become job-ready.',
  },
  {
    id: 4,
    question: 'Are the courses beginner-friendly?',
    answer: 'Yes! Our courses are designed for learners at various levels. Whether you’re a complete beginner or have some experience, we start with foundational concepts and progressively dive deeper into more advanced topics to ensure you gain practical skills.',
  },
  {
    id: 5,
    question: 'What programming languages will I learn in the Web Development course?',
    answer: 'In our Web Development course, you’ll learn HTML, CSS, JavaScript, React, and other popular front-end and back-end technologies. We focus on building full-stack applications, so you’ll be ready for any web development role.',
  },
  {
    id: 6,
    question: 'What tools and technologies will I use in the Machine Learning course?',
    answer: 'In the Machine Learning course, you’ll work with popular tools like Python, TensorFlow, Keras, and Scikit-learn. You will also work on data preprocessing, model building, and optimization techniques to create real-world machine learning models.',
  },
  {
    id: 7,
    question: 'How does Enack support my career after completing the course?',
    answer: 'Enack offers career services including resume building, interview preparation, job placement support, and access to a network of industry professionals. We are committed to helping you launch your career in tech or business analytics.',
  },
  {
    id: 8,
    question: 'Do you offer online or offline classes for these courses?',
    answer: 'At Enack, we offer both online and offline classes. Our hybrid approach gives you the flexibility to choose the learning format that best suits your schedule and preferences, while still receiving top-notch support and guidance.',
  },
  {
    id: 9,
    question: 'Will I get a certificate after completing a course?',
    answer: 'Yes, upon successful completion of any course at Enack, you’ll receive a certificate that demonstrates your proficiency in the subject matter. This certificate can be added to your resume or LinkedIn profile to showcase your skills to potential employers.',
  },
  {
    id: 10,
    question: 'How do I get started with the courses at Enack?',
    answer: 'To get started, simply sign up on our website, choose the course that fits your career goals, and enroll. You’ll be able to start learning immediately and get access to course materials, assignments, and live sessions.',
  },
  {
    id: 11,
    question: 'Are there any prerequisites for the Machine Learning and Web Development courses?',
    answer: 'For the Machine Learning course, a basic understanding of Python programming is recommended. For Web Development, no prior experience is required, but a basic familiarity with computers will help. We provide all the resources you need to succeed, regardless of your prior experience.',
  },
  {
    id: 12,
    question: 'Can I change my course or switch between career tracks?',
    answer: 'Yes, if you find that your interests change, we allow you to switch between courses or tracks (e.g., from Web Development to Business Analytics) with ease. We are here to support your evolving career aspirations.',
  },
  {
    id: 13,
    question: 'How can I track my progress during the course?',
    answer: 'Enack provides detailed progress tracking within your account dashboard. You’ll be able to view your completed modules, assignments, grades, and any areas that need improvement. You can track your progress in real-time.',
  },
  {
    id: 14,
    question: 'Are there group projects or hands-on opportunities in the courses?',
    answer: 'Absolutely! Our courses are designed to be hands-on. You will work on real-world projects, build portfolios, and collaborate with other learners in group assignments to simulate actual work environments and challenges.',
  },
  {
    id: 15,
    question: 'Can I access Enack on mobile devices?',
    answer: 'Yes, Enack is fully accessible on mobile devices. You can access course materials, lectures, and assignments on both Android and iOS devices through our mobile app, ensuring that you can learn on-the-go.',
  },
];

const FAQ = () => {
  const [expandedIndexes, setExpandedIndexes] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState(questions);
  const [searchTerm, setSearchTerm] = useState('');

  const handleToggle = (id) => {
    setExpandedIndexes(prev =>
      prev.includes(id) ? prev.filter(index => index !== id) : [...prev, id]
    );
  };

  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    const filtered = questions.filter(({ question }) =>
      question.toLowerCase().includes(searchTerm)
    );
    setFilteredQuestions(searchTerm ? filtered : questions);
  };

  const highlightSearchTerm = (text) => {
    if (!searchTerm) return text;
    const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));
    return parts.map((part, index) =>
      part.toLowerCase() === searchTerm.toLowerCase() ? <mark key={index}>{part}</mark> : part
    );
  };

  return (
    <>
      <Helmet>
        <title>Enack - Machine Learning, Web Development, & Business Analytics FAQ</title>
        <meta name="description" content="Find answers to frequently asked questions about Enack's Machine Learning, Web Development, and Business Analytics courses. Learn how to get started and succeed in your career." />
        <meta name="keywords" content="Enack, FAQ, machine learning, web development, business analytics, career courses, online learning" />
        <meta property="og:title" content="Enack - Career-Focused Courses FAQ" />
        <meta property="og:description" content="Find answers to frequently asked questions about Enack's Machine Learning, Web Development, and Business Analytics courses. Learn how to get started and succeed in your career." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.enack.com/faq" />
      </Helmet>
      <div className="faq-container">
        <div className="search-bar">
          <input 
            type="text" 
            placeholder="Search FAQ" 
            value={searchTerm}
            onChange={handleSearch} 
            aria-label="Search FAQ"
          />
        </div>
        <h2>Frequently Asked Questions</h2>
        {filteredQuestions.length === 0 && (
          <p>No results found for "{searchTerm}".</p>
        )}
        <Accordion allowMultipleExpanded>
          {filteredQuestions.map((question) => (
            <AccordionItem 
              key={question.id} 
              uuid={question.id} 
              expanded={expandedIndexes.includes(question.id)}
              className="accordion-item"
            >
              <AccordionItemButton 
                onClick={() => handleToggle(question.id)}
                className="accordion-button"
                aria-expanded={expandedIndexes.includes(question.id)}
                aria-controls={`panel-${question.id}`}
                id={`button-${question.id}`}
              >
                <h3>{highlightSearchTerm(question.question)}</h3>
                <span className={`accordion-icon ${expandedIndexes.includes(question.id) ? 'expanded' : ''}`}></span>
              </AccordionItemButton>
              <AccordionItemPanel 
                id={`panel-${question.id}`} 
                aria-labelledby={`button-${question.id}`}
                className="accordion-panel"
              >
                <p>{question.answer}</p>
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </>
  );
};

export default FAQ;
